// France
export const locale = {
	TRANSLATOR: {
		SELECT: "choisissez votre langue"
	},

	MENU: {
		NEW: "Nouveau",
		ACTIONS: "Actes",
		CREATE_POST: "Créer un nouveau Post",
		PAGES: "Pages",
		FEATURES: "Fonctionnalités",
		APPS: "Applications",
		DASHBOARD: "Tableau de Bord"
	},
	AUTH: {
		FORGOT: {
			TITLE: "Forgot Password?",
			DESC: "Enter your email to reset your password",
			SUCCESS: "Your account has been successfully reset."
		},
		TEXT: {
			CONNECTED: "Se connecter",
			ERROR_LOGIN_TITLE: "Se connecter",
			ERROR_LOGIN_TEXT: "L’e-mail entré ne correspond à aucun compte."
		},
		INPUT: {
			EMAIL: "Adresse e-mail",
			PASSWORD: "Mot de passe",
		},
		BUTTON : {
			SIGN_IN: "Connexion",
			FORGOT: "Mot de passe oublié ?"
		},
		VALIDATION: {
			EMAIL: "Adresse e-mail est requis",
			EMAIL_VALID: "La valeur n'est pas une adresse e-mail valide",
			PASSWORD: "Mot de passe est requis",
			REQUIRED: "{{name}} est requis"
		}
	},
	OVERVIEW: {
		TEXT: {
			LIVE:"Live",
			MODAL_TITLE_SETTINGS:"Paramètre",
			MODAL_ZOOM:"Réinitialiser la carte",
		},
		
	},
	LIVE: {
		TITLE: {
			
		},
		
	},
	REPORTING: {
		TITLE: {
			REPORTING_GENERAL:"Flotte",
			REPORTING_CAR_GENERAL:"Par véhicule",
			REPORTING_CAR_DETAIL:"Trajets",
		},
		
	},
	TEXT_ALL: {
		VEHICLE : "Véhicule",
		VIN_NUMBER : "VIN",
		LAST_MESSAGE : "Dernier message",
		ODOMETER : "Odomètre",
		IGNITION : "Moteur",
		MOTION : "Mouvement",
		LOCATION : "Position",
		SPEED : "Vitesse",
		DRIVER : "Conducteur",
		KM : "Km",
		KM_H : "Km/h",
		MOVING_FOR : "En marche",
		PARKING_FOR : "À l'arrêt",
		PRIVATE_MOD : 'Mode privé',
		GROUPE : 'Groupe',
		STATUS : 'Status',//a l'arret depuis
		NO_VIN : 'VIN Vide',
		POWER_VOLTAGE : 'Tension d\'alimentation',
		FUELLEVEL : 'Niveau de carburant',
		MSG_NO_IMMATRICULATION : 'Immatriculation Vide',
		IMMATRICULATION : 'Immatriculation',
		DATE_START : "Du",
		DATE_END : "au",
		OK : "Ok",
		CANCEL : "Annuler"
	},
	PARAMS: {
		201:"Réinitialisation ...",
		2101:"Véhicule à l''arrêt sans couverture GPS",
		211:"Alimentation activée",
		210:"Alimentation désactivée",
		212:"Température moteur",
		213:"Température du liquide de refroidissement moteur acceptable",
		1010:"",
		1020:"",
		1100:"Moteur arrêté",
		1110:"Moteur allumé",
		1200:"",
		1210:"",
		2100:"Véhicule à l''arrêt",
		2110:"Véhicule en marche",
		2120:"Véhicule en marche - virage à gauche",
		2121:"Véhicule en marche - virage à droite",
		2130:"Véhicule en marche",
		2140:"Véhicule en mouvement (probablement en remorquage)",
		2500:"Mode économie d''énergie",
		3210:"",
		3200:"Début remorquage",
		3220:"Fin remorquage",
		3300:"Véhicule au ralenti",
		3310:"Fin de l''alerte véhicule au ralenti",
		3320:"",
		3400:"1er message GPS après correction",
		3410:"Message GPS Valide après erreur",
		3420:"Pas de couverture GPS",
		4010:"",
		4101:"",
		4201:"",
		9100:"Mise à jour accomplie",
		9200:"Autocalibration accomplie",
		9900:"Protocole OBD détecté",
		9910:"Temps de détection du protocole OBD dépassé",
		10100:"",
		10110:"",
		10200:"Limite du Régime moteur dépassée",
		10210:"Régime moteur acceptable",
		10400:"",
		10410:"",
		10500:"",
		10510:"",
		10700:"Dysfonctionnement",
		10710:"Notification de dysfonctionnement épuré",
		10800:"Niveau batterie critique",
		10810:"Niveau batterie acceptable",
		10900:"Niveau de carburant faible",
		10910:"Niveau de carburant acceptable",
		11000:"Mouvement détecté",
		11010:"Aucun mouvement détecté",
		11100:"Accélération brusque",
		11110:"Comportement de conduite normal suite à une accélération sévère",
		11200:"Accélération risquée",
		11210:"Comportement de conduite normal suite à une accélération sévère",
		11300:"Accélération dangereuse",
		11310:"Comportement de conduite normal suite à une accélération sévère",
		11320:"Accélération sévère : Seuil Limite 4 dépassé",
		11321:"Comportement de conduite normal suite à une accélération sévère",
		11400:"Freinage excessif",
		11410:"Comportement de conduite normal suite à un freinage brusque",
		11500:"Freinage risqué",
		11510:"Comportement de conduite normal suite à un freinage brusque",
		11600:"Freinage dangereux",
		11610:"Comportement de conduite normal suite à un freinage brusque",
		11621:"Comportement de conduite normal suite à un freinage brusque",
		99001:"Virage à gauche brusque",
		99002:"Virage à gauche risqué",
		99003:"Virage à gauche dangereux",
		99004:"Virage à droite brusque",
		99005:"Virage à droite risqué",
		99006:"Virage à droite dangereux",
		99011:"",
		99012:"",
		99013:"Accélération Latérale sévère : Seuil Limite dépassé",
		99014:"Comportement de conduite normal suite à une accélération latérale sévère",
		100:"Message de configuration",
		217:"SMS reçu",
		99100:"Impact détecté",
		99200:"Fichier log du crash enregistré",
		99300:"Fichier log du crash non enregistré",
		214:"Signal GSM brouillé",
		5310:"Antidémarrage activé",
		5320:"Antidémarrage désactivé"
	}
};
