import Vue from "vue";
import axios from "axios";
//import VueAxios from "vue-axios";
//import JwtService from "@/core/services/jwt.service";

/**
 * Service to call HTTP request via Axios
 */
 axios.defaults.withCredentials = true

const ApiService = {
	init() {
		//Vue.use(VueAxios, axios);
		axios.defaults.withCredentials = true;
		axios.defaults.baseURL = "https://app.sayarte.ch/api/";
	},

  /**
   * Set the default HTTP request headers
   */
	setHeader() {
		/*axios.defaults.headers.common["Authorization"] = `Token ${JwtService.getToken()}`;
		axios.defaults.withCredentials = true;
		axios.defaults.headers.common["Content-Type"] =  "application/json";*/
		axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded;charset=UTF-8";
	},

	query(resource, params) {
		return axios.get(resource, params).catch(error => {
			throw new Error(`[KT] ApiService ${error}`);
		});
	},

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
	get(resource, slug = "") {
		axios.defaults.withCredentials = true;
		axios.defaults.baseURL = "https://app.sayarte.ch/api/";
		if(slug != ""){
			console.log(resource, slug);
			return axios.get(`${resource}?${slug}`).catch(error => {
			// console.log(error);
			throw new Error(`[KT] ApiService ${error}`);
			});
		}else{
			return axios.get(`${resource}`).catch(error => {
				throw new Error(`[KT] ApiService ${error}`);
			});

		}
	},

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
		axios.defaults.withCredentials = true;
		axios.defaults.baseURL = "https://app.sayarte.ch/api/";
		return axios.post(`${resource}`,params)
		.then(response => (
			this.info = response
		))
		.catch(error => console.log(error));
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
};

export default ApiService;
